<template>
  <b-card>
    <b-alert variant="primary" show>
      <h4 class="alert-heading">
        {{ $t('attention') }}
      </h4>
      <div class="alert-body">
        <b-row>
          <b-col md="12">
            <span class="h2 text-primary">{{
              $t('mustBeOnePricePolicyActive')
            }}</span>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <g-table
      ref="transaction-table"
      :items="items"
      :columns="tableColumns"
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          this.$router.push({ name: 'pricingPolicy-new' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap d-inline-flex">
          <b-form-checkbox
            checked="true"
            class="custom-control-primary mt-25"
            name="check-button"
            v-model="item.isActive"
            @change="
              (v) => {
                activation(item);
              }
            "
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="EyeIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="EyeOffIcon" />
            </span>
          </b-form-checkbox>
          <b-button
            data-action-type="delete"
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="
              (v) => {
                remove(item);
              }
            "
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      isTableBusy: false,
      totalRows: 0,
      items: [],
      totalVals: 0,
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'creationDateUtc',
          label: this.$t('creationDate'),
          sortable: true,
          formatter: (value, key, item) => {
            return this.getDate(item.creationDateUtc);
          },
        },
        {
          key: 'additionalUserPrice',
          label: this.$t('additionalUserPrice'),
          sortable: false,
        },
        {
          key: 'additionalBranchPrice',
          label: this.$t('additionalBranchPrice'),
          sortable: false,
        },
         {
           key: 'incorporationPrice',
           label: this.$t('incorporationPrice'),
           sortable: true,
        },
        // {
        //   key: 'additionalItemPrice',
        //   label: this.$t('additionalItemPrice'),
        //   sortable: true,
        // },
        {
          key: 'taxPercentage',
          label: this.$t('taxPercentage'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getPricingPolicy();
  },
  methods: {
    activation(item) {
      this.create({ url: `PricingPolicy/validate/${item.id}` })
        .then((data) => {
          if (item.isActive) {
            this.confirmActivate(
              {
                text: `${this.$t('pricePolicyWithDate')} ${this.getDate(
                  item.creationDateUtc
                )} ${this.$t('isActivate')}`,
              },
              () => {
                this.create({
                  url: `PricingPolicy/activate/${item.id}`,
                }).then(() => {
                  this.doneAlert({ text: this.$t('changeSuccessfully') });
                  this.getPricingPolicy();
                });
              }
            );
          }
        })
        .catch(({ data }) => {
          this.doneAlert({ text: this.$t(data.detail), type: 'error' });
          this.getPricingPolicy();
        });
    },
    getPricingPolicy() {
      this.get({ url: 'PricingPolicy' }).then((data) => {
        this.items = data;
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({
            url: 'PricingPolicy',
            id: item.id,
          }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getPricingPolicy();
          });
        }
      );
    },
    edit(item) {
      this.$router.push({
        name: 'pricingPolicy-edit',
        params: { id: item.id },
      });
    },
  },
};
</script>

<style></style>
