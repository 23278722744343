var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" "+_vm._s(_vm.$t('attention'))+" ")]),_c('div',{staticClass:"alert-body"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('span',{staticClass:"h2 text-primary"},[_vm._v(_vm._s(_vm.$t('mustBeOnePricePolicyActive')))])])],1)],1)]),_c('g-table',{ref:"transaction-table",attrs:{"items":_vm.items,"columns":_vm.tableColumns,"is-busy":_vm.isTableBusy,"noAction":true,"perPage":"25","totalRows":_vm.totalRows,"createButton":{ visiable: true },"searchInput":{ visiable: true }},on:{"on-create":function (v) {
        this$1.$router.push({ name: 'pricingPolicy-new' });
      }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-nowrap d-inline-flex"},[_c('b-form-checkbox',{staticClass:"custom-control-primary mt-25",attrs:{"checked":"true","name":"check-button","switch":""},on:{"change":function (v) {
              _vm.activation(item);
            }},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"EyeOffIcon"}})],1)]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('delete')),expression:"$t('delete')",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"data-action-type":"delete","variant":"flat-danger","size":"sm"},on:{"click":function (v) {
              _vm.remove(item);
            }}},[_c('feather-icon',{staticClass:"danger",attrs:{"icon":"TrashIcon","stroke":"red","id":("invoice-row-" + (item.id) + "-delete-icon")}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }